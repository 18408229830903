@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
/* body {
    background: #222831;
  } */

  .card-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding-top: 2em;
    min-height: 90vh;
  }
  
  /* h5 {
    font-family: sans-serif;
    color: rgb(194, 194, 194);
    margin: 0;
  } */
  /* h4 {
    font-family: sans-serif;
    color: rgb(194, 194, 194);
    margin: 10px 5px;
  } */
  
  #marked {
    font-family: Helvetica;
    margin: 0 auto;
    background: #5dc4de;
    text-align: center;
    /* font-weight: 600;
    font-size: 15pt; */
  }
  
  #bingotable {
    margin: 0 auto;
    text-align: center;
    width: 350px;
    height: 320px;
    border-collapse: collapse;
    background: white;
  }

  @media only screen and (max-width: 600px) {
    #bingotable {
        width: 280px;
        height: 250px;
    }
  }
  
  #bingotable th, td {
    font-family: Helvetica;
    margin: 0 auto;
    text-align: center;
    border: 3px solid black;
    width: 10%;
  }
  
  .orange {
    font-size: 20px;
    font-weight: bold;
    color: aliceblue;
    background: #9b59b6;
  }

  .numtxt {
    font-family: 'Roboto', sans-serif; font-size: 20pt;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    color: white;
  }

  .numlist {
    margin-top: 30px;
    color: white;
    text-align: center;
  }

  .numlist .nums {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 24px;
    float: left;
    margin: 3px;
    background-color: blue;
  }

  /* floating burron*/

  .fab {
    background-color: transparent;
    height: 64px;
    width: 64px;
    border-radius: 32px;
    transition: height 300ms;
    transition-timing-function: ease;
    position: fixed;
    right: 20px;
    bottom: 20px;
    text-align: center;
    overflow: hidden;
  }
  
  .fab:hover {
    height: 344px;
  }
  
  .fab:hover .mainop {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  
  .mainop {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 64px;
    height: 64px;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    background-color: #9c0288;
    border-radius: 32px;
    z-index: 6;
  }
  
  .mainopShadow {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  .mainop b {
    /* margin-top: 16px; */
    font-size: 25px;
    color: #fff;
  }
  
  .minifab {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    z-index: 5;
    float: left;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
    background-color: blue;
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  
  .minifab:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  .minifabIcon {
    height: 24px;
    width: 24px;
    margin-top: 12px;
  }
  
  .op1 {
    background-color: #2f82fc;
  }
  
  .op2 {
    background-color: #0f9d58;
  }
  
  .op3 {
    background-color: #fb0;
  }
  
  .op4 {
    background-color: #dd5145;
  }
  
  .op5 {
    background-color: #673ab7;
  }
  
html {
    overflow: scroll;
    overflow-x: hidden;
}
body {
    -webkit-tap-highlight-color: transparent;
    /* background: linear-gradient(#000, #222831, #000); */
    background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
    /* background-image: radial-gradient(circle, #096397, #124d79, #11385c, #0c2540, #051226); */
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    
}
.landing{
    background-image: url(/static/media/header.13a0210d.png) !important;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #101427;
}
