@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

html {
    overflow: scroll;
    overflow-x: hidden;
}
body {
    -webkit-tap-highlight-color: transparent;
    /* background: linear-gradient(#000, #222831, #000); */
    background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
    /* background-image: radial-gradient(circle, #096397, #124d79, #11385c, #0c2540, #051226); */
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    
}
.landing{
    background-image: url('../assets/img/header.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #101427;
}